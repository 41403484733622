import React, { createContext, useContext, ReactNode } from 'react';
import { productPrefix } from '../Utils/ProductPrefix.ts';
import { BasePrefixContextProps, BasePrefixProvider } from './BasePrefixContext.tsx';
const ProductRoutePrefixContext = createContext<string>(productPrefix);

export const useProductRoutePrefix = () => useContext(ProductRoutePrefixContext);

interface ProductRoutePrefixProviderProps extends BasePrefixContextProps {
    children: ReactNode;
}

export const ProductRoutePrefixProvider: React.FC<ProductRoutePrefixProviderProps> = ({ basePrefix, children }) => {
    return (
        <BasePrefixProvider basePrefix={basePrefix}>
            <ProductRoutePrefixContext.Provider value={productPrefix}>{children}</ProductRoutePrefixContext.Provider>
        </BasePrefixProvider>
    );
};

export default ProductRoutePrefixProvider;
